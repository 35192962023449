/**
 * URI处理模块
 * User: layenlin
 * Date: 13-12-13
 * Time: 上午10:31
 * To change this template use File | Settings | File Templates.
 */

/* eslint-disable */
import typeCheck from './type-check';

/**
 * 获取绝对路径
 *
 * @method getRealPath
 * @param {String} path 相对路径
 * @param {String} [target=location.href] 参考路径
 * @return {String} 绝对路径
 * @example
 * getRealPath('./../file.txt', 'http://vip.qq.com/a/b/c?name=value')
 * http://vip.qq.com/a/b/file.txt
 */
export function getRealPath (path, target) {
  let p = 0; let
    arr;
  /* Save the root, if not given */
  const r = target || window.location.href;
  /* Avoid input failures */
  path = (`${path}`).replace('\\', '/');
  /* Check if there's a port in path (like 'http://') */
  if (path.indexOf('://') !== -1) {
    p = 1;
  }
  /* Ok, there's not a port in path, so let's take the root */
  if (!p) {
    path = r.substring(0, r.lastIndexOf('/') + 1) + path;
  }
  /* Explode the given path into it's parts */
  arr = path.split('/');
  /* The path is an array now */
  path = [];
  /* Foreach part make a check */
  for (const k in arr) { /* This is'nt really interesting */
    if (arr.hasOwnProperty(k) && arr[k] == '.') {
      continue;
    }
    /* This reduces the realpath */
    if (arr.hasOwnProperty(k) && arr[k] == '..') {
      /* But only if there more than 3 parts in the path-array.
			 * The first three parts are for the uri */
      if (path.length > 3) {
        path.pop();
      }
    } /* This adds parts to the realpath */
    else {
      /* But only if the part is not empty or the uri
			 * (the first three parts ar needed) was not
			 * saved */
      if ((path.length < 2) || (arr[k] !== '')) {
        path.push(arr[k]);
      }
    }
  }
  /* Returns the absloute path as a string */
  return path.join('/');
}

/**
 * 解析url，返回组成部分
 *
 * @method parseUrl
 * @param {String} url 待解析的url
 * @return {Object|null} 解析成功返回URL组成部分，参考window.location。解析失败返回null
 * @example
 * parseUrl('http://www.qq.com:80/?name=value#hash')
 * 返回
 * {
	 *     hash: "#hash",
	 *     host: "www.qq.com",
	 *     hostname: "www.qq.com",
	 *     href: "http://www.qq.com/?name=value#hash",
	 *     pathname: "/",
	 *     port: "80",
	 *     protocol: "http:",
	 *     search: "?name=value"
	 * };
 */
export function parseUrl (url) {
  if (/^(([^:\/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/.test(url)) {
    const host = RegExp.$4.split(':');
    return {
      protocol: RegExp.$1,
      host: RegExp.$4,
      hostname: host[0],
      port: host[1] || '',
      pathname: RegExp.$5,
      search: RegExp.$6,
      hash: RegExp.$8,
      href: url,
    };
  }
  return null;
}

/**
 * 根据组成信息生成URL，是uri.parseUrl的反向操作
 *
 * @method getUri
 * @param {Object} location
 * @param {String} [location.hash] HASH值
 * @param {String} [location.search] SEARCH值
 * @param {String} [location.pathname] PATHNAME值
 * @param {String} [location.host] HOST值
 * @param {String} [location.protocol] PROTOCOL值
 * @return {String} 结果URL
 */
export function getUri (location) {
  let uri = '';
  if (location.hash) {
    uri = location.hash.indexOf('#') == 0 ? location.hash : `#${location.hash}`;
  }
  if (location.search) {
    uri = (location.search.indexOf('?') == 0 ? location.search : `?${location.search}`) + uri;
  }
  if (location.pathname) {
    uri = location.pathname + uri;
  } else {
    return uri;
  }
  if (location.host && uri.indexOf('/') == 0) {
    uri = location.host + uri;
  } else {
    return uri;
  }
  if (location.protocol) {
    uri = `${location.protocol}//${uri}`;
  }
  return uri;
}

/**
 * 将query string解析为object
 *
 * @method parseQueryString
 * @param {String} queryString 待解析的查询字符串
 * @return {Object} 解析结果
 * @example
 * parseQueryString('name=value&hash[a]=A&hash[b]=B') : {
	 *     "name": "value",
	 *     "hash": {
	 *         "a": "A",
	 *         "b": "B"
	 *     }
	 * };
 */
export function parseQueryString (queryString) {
  const strArr = String(queryString).replace(/^[\?&#]/, '').replace(/&$/, '').split('&');
  const sal = strArr.length;
  let i; let j; let ct; let p; let lastObj; let obj; let lastIter; let undef; let chr; let tmp; let key; let value;
  let postLeftBracketPos; let keys; let keysLen;
  const fixStr = function (str) {
    try {
      return decodeURIComponent(str.replace(/\+/g, '%20'));
    } catch (e) {
      return '';
    }
  };
  const array = {};

  for (i = 0; i < sal; i++) {
    tmp = strArr[i].split('=');
    key = fixStr(tmp[0]);
    value = (tmp.length < 2) ? '' : fixStr(tmp[1]);

    while (key.charAt(0) === ' ') {
      key = key.slice(1);
    }
    if (key.indexOf('\x00') > -1) {
      key = key.slice(0, key.indexOf('\x00'));
    }
    if (key && key.charAt(0) !== '[') {
      keys = [];
      postLeftBracketPos = 0;
      for (j = 0; j < key.length; j++) {
        if (key.charAt(j) === '[' && !postLeftBracketPos) {
          postLeftBracketPos = j + 1;
        } else if (key.charAt(j) === ']') {
          if (postLeftBracketPos) {
            if (!keys.length) {
              keys.push(key.slice(0, postLeftBracketPos - 1));
            }
            keys.push(key.substr(postLeftBracketPos, j - postLeftBracketPos));
            postLeftBracketPos = 0;
            if (key.charAt(j + 1) !== '[') {
              break;
            }
          }
        }
      }
      if (!keys.length) {
        keys = [key];
      }
      for (j = 0; j < keys[0].length; j++) {
        chr = keys[0].charAt(j);
        if (chr === ' ' || chr === '.' || chr === '[') {
          keys[0] = `${keys[0].substr(0, j)}_${keys[0].substr(j + 1)}`;
        }
        if (chr === '[') {
          break;
        }
      }

      obj = array;
      for (j = 0, keysLen = keys.length; j < keysLen; j++) {
        key = keys[j].replace(/^['"]/, '').replace(/['"]$/, '');
        lastIter = j !== keys.length - 1;
        lastObj = obj;
        if ((key !== '' && key !== ' ') || j === 0) {
          if (obj[key] === undef) {
            obj[key] = {};
          }
          obj = obj[key];
        } else { // To insert new dimension
          ct = -1;
          for (p in obj) {
            if (obj.hasOwnProperty(p)) {
              if (+p > ct && p.match(/^\d+$/g)) {
                ct = +p;
              }
            }
          }
          key = ct + 1;
        }
      }
      lastObj[key] = value;
    }
  }
  return array;
}

const escape = encodeURIComponent;

/**
 * 将object解析为query string
 * @param obj
 * @param traditional
 * @returns {string}
 */
export function jsonToQueryString (obj, traditional) {
  const params = [];
  params.add = function (k, v) {
    this.push(`${escape(k)}=${escape(v)}`);
  };
  serialize(params, obj, traditional);
  return params.join('&').replace(/%20/g, '+');
}

function serialize (params, obj, traditional, scope) {
  let type; let value; const array = typeCheck.isArray(obj); const
    hash = typeCheck.isPlainObject(obj);

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      value = obj[key];
      type = typeCheck.type(value);
      if (scope) {
        key = traditional ? scope
          : `${scope}[${hash || type == 'object' || type == 'array' ? key : ''}]`;
      }
      // handle data in serializeArray() format
      if (!scope && array) params.add(value.name, value.value);
      // recurse into nested objects
      else if (type == 'array' || (!traditional && type == 'object')) serialize(params, value, traditional, key);
      else params.add(key, value);
    }
  }
}

/**
 * 给URL添加/替换多个参数，例如在url后面添加/替换_wv=1027
 *
 * @param url {string}
 * @param params {object}
 *
 * @return {string}
 */
export function appendParams (url, params) {
  const obj = parseUrl(url) || {};
  const search = obj.search || '';
  const urlPrefix = url.indexOf('?') > 0 ? url.slice(0, url.indexOf('?')) : url;
  const param = parseQueryString(search) || {};

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      param[key] = params[key];
    }
  }

  url = `${urlPrefix}?${jsonToQueryString(param)}`;
  return url;
}

/**
 * 给URL添加/替换多个参数，例如在url后面添加/替换_wv=1027, 并清理deletedParams 中包含的参数
 * @param url
 * @param appendParams Object
 * @param deletedParams Array
 * @returns {string|*}
 */
export function modifyParams (url, appendParams, deletedParams) {
  !url && (url = '');
  const obj = parseUrl(url) || {};
  const search = obj.search || '';
  const urlPrefix = url.indexOf('?') > 0 ? url.slice(0, url.indexOf('?')) : url;
  const param = parseQueryString(search) || {};

  if (deletedParams) {
    for (const key of deletedParams) {
      if (param.hasOwnProperty(key)) {
        delete param[key];
      }
    }
  }
  if (appendParams) {
    for (const key in appendParams) {
      if (appendParams.hasOwnProperty(key)) {
        param[key] = appendParams[key];
      }
    }
  }

  url = `${urlPrefix}?${jsonToQueryString(param)}`;
  return url;
}

export function getUrlQuery (url) {
  const urlLocation = parseUrl(url) || {};
  return parseQueryString(urlLocation.search) || {};
}

export function getUrlParams (url, params) {
  const query = getUrlQuery(url);
  return query[params];
}

import { serverWindow } from '@/util/env';

/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
// 判断ua中是否有开启提审开关
function uaSwitchOn(ua) {
  const matches = ua.match(/\sSUB\/([\d])/);
  if (matches && matches[1] && Number(matches[1]) === 1) {
    return true;
  }
  return false;
}

export function checkEnv() {
  const ua = serverWindow.navigator.userAgent;
  return {
    isApp: /com\.tencent\.gamecommunity/.test(ua),
    mobile: /AppleWebKit.*Mobile.*/i.test(ua),
    ios: new RegExp('\\(i[^;]+;( U;)? CPU.+Mac OS X', 'i').test(ua),
    android: /Android/i.test(ua) || /Linux/i.test(ua),
    windowsphone: /Windows Phone/i.test(ua),
    iPhone: /iPhone/i.test(ua),
    iPad: /iPad/i.test(ua),
    iPadQQ: /IPadQQ/i.test(ua),
    webApp: !/Safari/i.test(ua),
    MicroMessenger: /MicroMessenger/i.test(ua),
    weibo: /Weibo/i.test(ua),
    toutiao: /NewsArticle/i.test(ua),
    uc: /UCBrowser/i.test(ua),
    qq: /MQQBrowser/i.test(ua),
    baidu: /Baidu/i.test(ua),
    /* eslint-disable-next-line */
    mqq: /QQ\/([\d\.]+)/i.test(ua),
    qComic: /\bcom\.tencent\.nijigen/i.test(ua),
    mbaidu: /baiduboxapp/i.test(ua),
    iqiyi: /iqiyi/i.test(ua),
    QQLive: /QQLive/i.test(ua),
    Safari: /Safari/i.test(ua),
    Youku: /youku/i.test(ua),
    wx: /(micromessenger|webbrowser)/i.test(ua),
    isDebug: /ENV\/DEBUG/i.test(ua),
    isTest: /ENV\/TEST/i.test(ua),
    isPressure: /ENV\/PRESSURE/i.test(ua),
    isProd: /ENV\/RELEASE/i.test(ua),
    iosVersion: (() => {
      const result = ua.match(/ip[honead]{2,4}(?:.*os\s([\w]+)\slike\smac|;\sopera)/i);
      let version = '0';
      if (result && result[1]) {
        version = result[1].replace('_', '.');
      }
      return version;
    })(),
    isSwitchOn: (/(iPad|iPhone|iPod)/).test(ua) && uaSwitchOn(ua), // ios提审开关
    isAndroidSwitchOn: (/Android/i.test(ua) || /Linux/i.test(ua)) && uaSwitchOn(ua), // 安卓提审开关
    isGameHelper: /GameHelper_[\d]+/.test(ua), // 是否游戏助手
    isIOS_11_1_2: /iPhone\sOS\s11_1_2/.test(ua) || /iPhone\sOS\s11_0_1/.test(ua),
  };
}

/*
 * iphone 型号表
iPhone10,1 => IPHONE_8_CN;
iPhone10,2 => IPHONE_8_PLUS_CN;
iPhone10,3 => IPHONE_X_CN;
iPhone10,4 => IPHONE_8;
iPhone10,5 => IPHONE_8_PLUS;
iPhone10,6 => IPHONE_X;

iPhone11,2 => IPHONE_XS;
iPhone11,4 => IPHONE_XS_MAX;
iPhone11,6 => IPHONE_XS_MAX_CN;
iPhone11,8 => IPHONE_XR;

iPhone12,1 =>IPHONE_11;
iPhone12,3 =>IPHONE_11_PRO;
iPhone12,5 =>IPHONE_11_PRO_MAX;
*/

/** 判断是否iPhoneX、iPhone11等刘海屏
 * 1.4版本中添加的ua HDW 为设备型号
 */
export function isIPhoneFullScreen(userAgent = '') {
  const ua = userAgent || serverWindow.navigator.userAgent;
  const checkEnvObj = checkEnv();
  if (!checkEnvObj.isApp || !checkEnvObj.ios) return false;
  const h = /HDW\/iPhone([\d,]+)/.exec(ua);
  if (h && h[1]) {
    const iPhoneHardwareCode = h[1];
    if (Number(iPhoneHardwareCode.split(',')[0]) > 10) {
      return true;
    }
  }
  return false;
}

export const env = checkEnv();

export default {
  checkEnv,
  env,
  isIPhoneFullScreen,
};

/**
 * Aegis监控
 * 文档 http://aegis.oa.com/sdk/web.html
 */
import cookie from 'js-cookie';
import { isServerRender } from '@/util/env';

// const aegisId = process.env.VUE_APP_PUBLIC_ENV === 'official' ? 2199 : 2200;

// 配置需要上报时长和错误的cig域名
const CGI_DOMAIN_LIST = [
  'gameplus.qq.com', 'shanxian.qq.com',
];

// 判断是否为社区域名
function isShanxianApi(url) {
  return CGI_DOMAIN_LIST.some(item => url.indexOf(item) > -1);
}

const retCodeWiteList = [
  90404, // 礼包维护中，比如下架了
  20001, // 手机号已绑定
];

const isInvalidRetCode = retCode => retCode !== 0 && retCodeWiteList.indexOf(retCode) === -1;

const aegis = {
  report() {
    //
  },
  info() {
    //
  },
  infoAll() {
    //
  },
  setConfig() {
    //
  },
};
if (!isServerRender && window.aegisIns) {
  const realAegis = window.aegisIns;
  realAegis.setConfig({
    uin: cookie.get('uid') || '0', // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    offlineLogExp: 10,
    offlineLog: true,
    beforeReportSpeed(msg) {
      if (!isShanxianApi(msg.url)) {
        return false;
      }
      return msg;
    },
    beforeReport(msg) {
      if (msg.level === '16') { // ajax错误
        if (!isShanxianApi(msg.msg)) {
          return false;
        }
      }
      if (msg.level === '64') { // IMG load error
      // 这个是分享sdk引入的上报接口，过滤报错
        if (msg.msg.indexOf('https://pingfore.qq.com/pingd') > -1) {
          return false;
        }
      }
      return msg;
    },
    api: {
      retCodeHandler(data) {
        if (typeof data === 'string') {
          try {
            const body = JSON.parse(data);
            return {
            // 判断存在ret字段来判断是社区cgi
              isErr: typeof body.ret !== 'undefined' && isInvalidRetCode(body.ret),
              code: body.ret,
            };
          } catch (error) {
          // 非json格式，不管
          // console.error('retCodeHandler json parse error', error);
          }
        }
        return undefined;
      },
    },
  });
  Object.assign(aegis, realAegis);
}

// 自动从cookie中获取uid，登录状态改变后需要执行
export function updateAegis() {
  const uid = cookie.get('uid') || '0';
  aegis.setConfig({
    uin: uid,
  });
}

export default aegis;

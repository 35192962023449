// const QuestionSkeleton = require('../assets/skeleton/community/question-detail.html');
import * as QuestionSkeleton from './community/question-detail.html';
import * as AnswerSkeleton from './community/answer-detail.html';
import * as PostSkeleton from './community/post-detail.html';
import * as PostVideoSkeleton from './community/post-video.html';
import * as AnswerInviteSkeleton from './community/answer-invite.html';

const skeletonMap = [
  {
    path: /\/community\/question-detail(|\/|(\/index.html))$/,
    skeleton: QuestionSkeleton,
  },
  {
    path: /\/community\/answer-detail(|\/|(\/index.html))$/,
    skeleton: AnswerSkeleton,
  },
  {
    path: /\/community\/post-detail(|\/|(\/index.html))$/,
    skeleton: PostSkeleton,
  },
  {
    path: /\/community\/post-video(|\/|(\/index.html))$/,
    skeleton: PostVideoSkeleton,
  },
  {
    path: /\/community\/answer-invite(|\/|(\/index.html))$/,
    skeleton: AnswerInviteSkeleton,
  },
];

export default skeletonMap;

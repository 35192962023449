import skeletonMap from './map';
import { checkEnv } from '../util/browser-env';
import aegis from '../util/aegis';

const checkEnvObj = checkEnv();

class Skeleton {
  constructor() {
    if (window.skeleton) {
      return window.skeleton;
    }
    this.activeSkeleton = null;
    this.init();
    this.isActive = false;
  }

  load(path) {
    let matchSkeleton = null;
    if (this.activeSkeleton === path) {
      return;
    }
    this.activeSkeleton = path;
    skeletonMap.some((v) => {
      const checkType = this.getType(v.path);
      const regExpFlag = checkType === '[object RegExp]' && v.path.test(path);
      const StringFlag = checkType === '[object String]' && path.includes(v.path);
      if (regExpFlag || StringFlag) {
        matchSkeleton = v;
        return true;
      }
      return false;
    });
    if (matchSkeleton) {
      this.open(matchSkeleton.skeleton);
    }
  }

  open(skeletonStr = '') {
    let styleContent = skeletonStr.match(/<style.*role=style.*?>([\s\S]*)<\/style>/i);
    let bodyContent = skeletonStr.match(/<body.*role=body.*?>([\s\S]*)<\/body>/i);
    const styleRoot = document.head.querySelector('#skeleton-style');
    const skeletonRoot = document.body.querySelector('#skeleton-body');

    if (checkEnvObj.isApp) {
      skeletonRoot.classList.add('is-app');
    }

    if (checkEnvObj.ios) {
      skeletonRoot.classList.add('platform-ios');
    } else if (checkEnvObj.android) {
      skeletonRoot.classList.add('platform-android');
    }

    const vueRoot = document.querySelector('#app');
    styleContent = styleContent && styleContent[1];
    bodyContent = bodyContent && bodyContent[1];
    styleRoot.innerHTML = styleContent;
    skeletonRoot.innerHTML = bodyContent;
    vueRoot.style.opacity = 'none';
    skeletonRoot.style.display = 'block';

    this.isActive = true;
    // 错误告警，10m后还在骨架屏就触发告警
    setTimeout(() => {
      if (this.isActive) {
        aegis.report('骨架屏超过时长10s');
      }
    }, 10000);
  }

  close() {
    const vueRoot = document.querySelector('#app');
    const skeletonRoot = document.querySelector('#skeleton-body');
    vueRoot.style.opacity = 'block';
    skeletonRoot.style.display = 'none';
    this.isActive = false;
  }

  getType(variable) {
    return Object.prototype.toString.call(variable);
  }

  init() {
    window.skeleton = this;
    // document.addEventListener('readystatechange', () => {
    //   if (document.readyState === 'interactive') {
    this.load(window.location.pathname);
    //   }
    // });
  }
}
export default new Skeleton();

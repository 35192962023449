/* eslint-disable no-underscore-dangle */
/**
 * Created by Dreamzhu on 2017/3/25.
 */

import { parseUrl } from '@/util/uri';

// 是否在服务端运行
export const isServerRender = process.env.VUE_ENV === 'server';

// 服务端的windows
// eslint-disable-next-line import/no-mutable-exports
export let vueSsrContext;
// eslint-disable-next-line import/no-mutable-exports
export let tswPlug;
// eslint-disable-next-line import/no-mutable-exports
export let serverDocument;
// eslint-disable-next-line import/no-mutable-exports
export let serverWindow;
// eslint-disable-next-line import/no-mutable-exports
export let serverWindowProtocol;
// eslint-disable-next-line import/no-mutable-exports
export let tegMonitor;


if (isServerRender) {
  vueSsrContext = __VUE_SSR_CONTEXT__;  // eslint-disable-line
  tswPlug = vueSsrContext.tswPlug; // eslint-disable-line
  tegMonitor = vueSsrContext.tegMonitor;  // eslint-disable-line
  const { request } = vueSsrContext;

  // document定义
  serverDocument = {
    cookie: request.headers.cookie || '',
  };

  serverWindow = {
    location: parseUrl((`${request.protocol}://${request.hostname}${request.url}`) || ''),
    navigator: {
      userAgent: request.headers['user-agent'] || '',
    },
    console: {}, // todo
  };

  serverWindowProtocol = `${request.protocol}:`;
} else {
  serverWindow = window;
  serverDocument = document;
  serverWindowProtocol = window.location.protocol;
}


// 是否为异步版
export const isAsyncRender = !(serverWindow && serverWindow.__INITIAL_STATE__);
